import React, { useEffect, useState } from "react";
import { useUserAuth } from "../context/UserAuthContext";
import QRCode from "react-qr-code";
import ContadorVisitas from "./ContadorVisitas";
import '../css/inicio.css';
import { formatPhoneNumber } from 'react-phone-number-input';
import { Modal } from "react-bootstrap";
import toast, { Toaster } from 'react-hot-toast';

const Inicio = () => {
  const { logOut, user } = useUserAuth();
  const [ nombre, setNombre ] = useState('');
  const [ diaCumpleanos, setDiaCumpleanos ] = useState('');
  const [ mesCumpleanos, setMesCumpleanos ] = useState('');
  const [ visitas, setVisitas ] = useState('');
  const [ visitasTotales, setVisitasTotales ] = useState('');
  const [ recompensas, setRecompensas ] = useState([]);
  const [ recompensaActiva, setRecompensaActiva ] = useState([]);
  const [ seeModalRecompensa, setSeeModalRecompensa ] = useState(false);
  const [ showModalQR, setShowModalQR ] = useState(false);

  const handleLogout = async () => {
      try {
          await logOut();
      }catch (error) {
          console.log(error.message);
          toast.error('Error al cerrar sesión.');
      }
  };

  useEffect(() => {
    async function getRecompensas(){      
      const requestOptionsUsuario = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ operacion: 'selectWhere', telefono: user.phoneNumber })
      };
      const responseUsuario = await fetch('https://tarjetalealtad.argeos.net/php/DB/consultas.php', requestOptionsUsuario);
      const dataUsuario = await responseUsuario.json();
      if (dataUsuario.respuesta) {
        const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ operacion: 'selectRecompensas' })
        };
        const response = await fetch('https://tarjetalealtad.argeos.net/php/DB/consultas.php', requestOptions);
        const data = await response.json();
        if (data.error === 0) {
          setRecompensas(data.recompensas);
          const requestOptionsDatos = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ operacion: 'selectDatos', telefono: user.phoneNumber })
          };
          const responseDatos = await fetch('https://tarjetalealtad.argeos.net/php/DB/consultas.php', requestOptionsDatos);
          const dataDatos = await responseDatos.json();
          if (dataDatos.error === 0) {
            let visitas = '';
            let recompensaActivaAgregar = [];
            if (data.recompensas.length > 0) {
              let visitasMax = data.recompensas[data.recompensas.length - 1].noVisitas;
              visitas = dataDatos.data.visitas%visitasMax;
              if(data.recompensas.find(e => Number(e.noVisitas) === (visitas + 1))) {
                recompensaActivaAgregar = data.recompensas.find(e => Number(e.noVisitas) === (visitas + 1));
              }
            }
            setRecompensaActiva(recompensaActivaAgregar);
            setNombre(dataDatos.data.nombre);
            setDiaCumpleanos(dataDatos.data.diaCumpleanos);
            setMesCumpleanos(dataDatos.data.mesCumpleanos);
            setVisitas(visitas);
            setVisitasTotales(dataDatos.data.visitas);
          }else{
            toast.error('Error al obtener información de usuario.');
            console.log(dataDatos.mensaje);
          }
        }else{
          toast.error('Error al obtener recompensas.');
          console.log(data.mensaje);
        }
      }else{
        try {
          await logOut();
        }catch (error) {
            console.log(error.message);
            toast.error('Error al cerrar sesión.');
        }
      }
    }
    getRecompensas();
  }, [user, logOut]);

  const getRecompensas = async () => {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ operacion: 'selectRecompensas' })
    };
    const response = await fetch('https://tarjetalealtad.argeos.net/php/DB/consultas.php', requestOptions);
    const data = await response.json();
    if (data.error === 0) {
      setRecompensas(data.recompensas);
      const requestOptionsDatos = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ operacion: 'selectDatos', telefono: user.phoneNumber })
      };
      const responseDatos = await fetch('https://tarjetalealtad.argeos.net/php/DB/consultas.php', requestOptionsDatos);
      const dataDatos = await responseDatos.json();
      if (dataDatos.error === 0) {
        let visitas = '';
        let recompensaActivaAgregar = [];
        if (data.recompensas.length > 0) {
          let visitasMax = data.recompensas[data.recompensas.length - 1].noVisitas;
          visitas = dataDatos.data.visitas%visitasMax;
          if(data.recompensas.find(e => Number(e.noVisitas) === (visitas + 1))) {
            recompensaActivaAgregar = data.recompensas.find(e => Number(e.noVisitas) === (visitas + 1));
          }
        }
        setRecompensaActiva(recompensaActivaAgregar);
        setNombre(dataDatos.data.nombre);
        setDiaCumpleanos(dataDatos.data.diaCumpleanos);
        setMesCumpleanos(dataDatos.data.mesCumpleanos);
        setVisitas(visitas);
        setVisitasTotales(dataDatos.data.visitas);
      }else{
        toast.error('Error al obtener información de usuario.');
        console.log(dataDatos.mensaje);
      }
    }else{
      toast.error('Error al obtener recompensas.');
      console.log(data.mensaje);
    }
  }

  const cerrarModalRecompensa = () => {
    setSeeModalRecompensa(false);
  }
  
  const abrirModalRecompensa = () => {
    setSeeModalRecompensa(true);
  }

  return (
    <>
      <div className="container-fluid p-4" style={{ overflowX: 'hidden' }}>
        <div className="card w-100 mx-auto animate__animated animate__zoomIn animate__fast" style={{ backgroundColor: 'rgb(255, 255, 255, 0.7)', border: 'none', borderRadius: '15px', minHeight: window.innerHeight - 50, maxHeight: 'fit-content', boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px', maxWidth: '700px' }}>
          <div className="card-header pt-4 px-4" style={{ backgroundColor: 'transparent', border: 'none' }}>
            <div className="row m-0">              
              <div className="col-12 p-0 d-flex align-items-center justify-content-center">
                <h3 className="m-0" style={{ fontWeight: '600' }}>Tarjeta de lealtad</h3>
              </div>
            </div>
          </div>
          <div className="card-body">
            <div className="row">
              <div className="col-12">
                <div className="card tarjetaCliente mx-auto">
                  <div className="card-header p-0" style={{ border: 'none', backgroundColor: 'transparent' }}>
                    <div className="row m-0">
                      <div className="col-12 p-0">
                        <p className="m-0" style={{ fontSize: '15px' }}>Ojo de Agua Pachuca</p>
                      </div>
                    </div>
                  </div>
                  <div className="card-body p-0 d-flex align-items-center">
                    <div className="w-100">
                      <div className="row m-0">
                        <div className="col-12 p-0">
                          <h5 className="m-0">{nombre}</h5>
                        </div>
                      </div>
                      <div className="row m-0 mt-2">
                        <div className="col-12 p-0 text-end">
                          <h6 className="m-0">{formatPhoneNumber(user.phoneNumber)}</h6>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card-footer p-0" style={{ border: 'none', backgroundColor: 'transparent' }}>
                    <div className="row m-0">
                      <div className="col-10 p-0 d-flex align-items-end">
                        {
                          diaCumpleanos !== '' && <p className="m-0">Cumpleaños: {diaCumpleanos}/{mesCumpleanos}</p>
                        }
                      </div>
                      <div className="col-2 p-0 text-end">
                        <img src="/img/logo_ODA_Blanco.svg" alt="logo" className="logo"/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-6 text-end">
                <button className="btnMostrarQR w-100 btn btn-dark mt-4 boxShadowLigero" style={{ maxWidth: '250px' }} onClick={() => setShowModalQR(true)}>Mostrar QR</button>
              </div>
              <div className="col-6">
              <button className="btnMostrarQR w-100 btn btn-primary text-white mt-4 boxShadowLigero" style={{ maxWidth: '250px' }} onClick={getRecompensas}>Actualizar</button>
              </div>
            </div>
            {
              recompensaActiva.idRecompensa &&
                <button className='recompensaActiva mt-4 animate__animated animate__fadeInUp' onClick={abrirModalRecompensa}>
                    <p className='m-0 text-center' style={{ fontSize: '16px' }}>¡Tienes una recompensa disponible en tu próxima visita!</p>
                    <p className='m-0 text-center' style={{ fontSize: '22px' }}>{recompensaActiva.descripcion}</p>
                    <p className='m-0 text-center mt-2' style={{ fontSize: '12px', fontWeight: '600' }}>Haz click aquí para mas información.</p>
                </button>
            }
            <ContadorVisitas visitas={visitas} visitasTotales={visitasTotales} recompensas={recompensas}/>
          </div>
          <div className="card-footer pb-3 text-center" style={{ border: 'none', backgroundColor: 'transparent' }}>
            <button className="btn btn-info text-white w-100 boxShadowLigero" onClick={handleLogout} style={{ borderRadius: '20px', fontWeight: '600', maxWidth: '250px' }}>Salir</button>
          </div>
        </div>
      </div>
      <Modal show={showModalQR} onHide={() => setShowModalQR(false)} centered className="modalQR">
        <Modal.Body className="m-2" style={{ backgroundColor: '#F0F9F7', borderRadius: '15px', border: '2px solid rgb(56, 62, 83)' }}>
            <div className="p-2" style={{ height: "auto", margin: "0 auto", width: "100%" }}>
                <QRCode
                  style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                  value={user.phoneNumber}
                  level="M"
                  bgColor="transparent"
                  fgColor="rgb(56, 62, 83)"
                />
            </div>
        </Modal.Body>
      </Modal>
      <Modal show={seeModalRecompensa} onHide={cerrarModalRecompensa} centered className='modalRecompensa'>
          <Modal.Header className='d-flex justify-content-center align-items-center' style={{ border: 'none' }}>
              <h5 className='m-0'>Recompensa {recompensaActiva.noVisitas} visitas</h5>
          </Modal.Header>
          <Modal.Body>
              <p className='m-0'>{recompensaActiva.descripcion}</p>
          </Modal.Body>
          <Modal.Footer className='d-flex justify-content-start'>
              <p className='m-0' style={{ fontSize: '12px', color: 'gray', fontWeight: '600' }}>{recompensaActiva.terminos}</p>
          </Modal.Footer>
      </Modal>
      <Toaster/>
    </>
  );
};

export default Inicio;


