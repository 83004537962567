import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { PrivateRoute } from './PrivateRoute';
import { LoginRoute } from './LoginRoute';
import NotFoundRoute from './NotFoundRoute';
import Inicio from "./Inicio";
import Acceso from './Acceso';

class Routes extends React.Component {
    constructor(props) {
        super(props);
        this.state = { 

         }
    }

    render() { 
        return ( 
            <Switch>
                <LoginRoute path='/acceso' component={Acceso}/>
                <PrivateRoute exact path="/" component={Inicio}/>
                <Route exact path="*" component={NotFoundRoute}/>
            </Switch>
         );
    }
}
 
export default Routes;