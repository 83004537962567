import Routes from './components/Routes';
import { BrowserRouter as Router } from 'react-router-dom';
import { UserAuthContextProvider } from "./context/UserAuthContext";

function App() {
  return (
    <UserAuthContextProvider>
      <Router>
          <Routes/>
      </Router>
    </UserAuthContextProvider>
  );
}

export default App;
