import React, { Component } from 'react';
import Login from './Login';
import Signup from './Signup';
import colores from '../css/bootstrap.scss';
import { Alert } from 'react-bootstrap';
import toast, { Toaster } from 'react-hot-toast';

class Acceso extends Component {
    constructor(props) {
        super(props);
        this.state = {
            vista: 'login',
            number: '',
            error : ''
        }
    }

    componentDidMount = () => {
        window.addEventListener('resize', this.resizeContainer);
        this.resizeContainer();
    }

    resizeContainer = () => {
        if (document.getElementsByClassName('card')[0]) {
            document.getElementsByClassName('card')[0].style.minHeight = (window.innerHeight - 50) + 'px';
        }
    }

    delay = ms => new Promise(res => setTimeout(res, ms));

    onChangeNumber = (number) => {
        this.setState({
            number: number
        })
    }

    onChangeVista = (vista) => {
        this.setState({
            vista: vista
        })
    }
    
    setError = (error) => {
        this.setState({
            error: error
        })
    }

    setToast = (error) => {
        toast(error, {
            style: {
              borderRadius: '10px',
              background: '#333',
              color: '#fff',
              textAlign: 'center'
            },
          });
    }

    render() { 
        return (   
                <div className="container-fluid p-4" style={{ overflowX: 'hidden', maxWidth: '500px' }}>
                    <div className="card w-100 animate__animated animate__zoomIn animate__fast" style={{ backgroundColor: 'rgb(255, 255, 255, 0.7)', border: 'none', borderRadius: '15px', minHeight: window.innerHeight - 50, maxHeight: 'fit-content', boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px' }}>
                        <div className="card-header pt-4 px-4" style={{ backgroundColor: 'transparent', border: 'none' }}>
                            <img src="/img/logo_ODA.svg" alt="" style={{ width: '40%' }} className="d-flex mx-auto mb-3"/>
                            <div className='d-flex justify-content-center align-items-center' style={{ height: '45px', width: '100%', borderRadius: '10px', backgroundColor: 'white', border: '2px solid', borderColor: colores.primary, color: colores.primary }}>
                                <h1 className='m-0' style={{ fontSize: '20px' }}>Ingresar</h1>
                            </div>                          
                            {this.state.error && <Alert variant="danger" className="animate__animated animate__headShake mt-4">{this.state.error}</Alert>}
                        </div>
                        {
                            this.state.vista === 'login' ?
                                <Login number={this.state.number} onChangeNumber={this.onChangeNumber} onChangeVista={this.onChangeVista} setError={this.setError} setToast={this.setToast}/>
                            :
                                <Signup number={this.state.number} onChangeNumber={this.onChangeNumber} onChangeVista={this.onChangeVista} setError={this.setError}/>
                        }
                    </div>
                    <Toaster/>
                </div>
         );
    }
}
 
export default Acceso;