import React from "react";
import ReactDOM from "react-dom";
import './css/style.css';
import 'animate.css';
import '../src/css/bootstrap.scss';
import App from "./App";

ReactDOM.render(
  <React.StrictMode>
      <App />
  </React.StrictMode>,
  document.getElementById("root")
);
