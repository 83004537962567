import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import "react-phone-number-input/style.css";
import PhoneInput, { isPossiblePhoneNumber } from "react-phone-number-input";
import es from 'react-phone-number-input/locale/es.json';
import { useUserAuth } from "../context/UserAuthContext";

const Login = (props) => {
    const [flag, setFlag] = useState(false);
    const [otp, setOtp] = useState("");
    const [result, setResult] = useState("");
    const { setUpRecaptha } = useUserAuth();
  
    const getOtp = async (e) => {
        e.preventDefault();
        await props.setError("");
        if (props.number === "" || props.number === undefined || !isPossiblePhoneNumber(props.number)){
            return props.setError("Ingrese un número telefónico válido.");
        }else{
            try{
                const requestOptions = {
                  method: 'POST',
                  headers: { 'Content-Type': 'application/json' },
                  body: JSON.stringify({ operacion: 'selectWhere', telefono: props.number })
                };
                const response = await fetch('https://tarjetalealtad.argeos.net/php/DB/consultas.php', requestOptions);
                const data = await response.json();
                if (data.error === 0) {
                  if (data.respuesta) {
                    const response = await setUpRecaptha(props.number);
                    setResult(response);
                    setFlag(true);              
                  }else{
                    props.setToast('Este número no se encuentra registrado, regístrese primero.');
                    props.onChangeVista('signup');
                  }
                }else{
                  props.setError('Error al buscar coincidencias.')
                  console.log(data.mensaje);
                }
            }catch (err) {
                props.setError(err.message);
            }
        }
    };
  
    const verifyOtp = async (e) => {
        e.preventDefault();
        await props.setError("");
        if (otp === "" || otp === null) return;
        try{
            await result.confirm(otp);
        }catch (err) {
            if (err.code === 'auth/invalid-verification-code') {
                props.setError('Código incorrecto.');
            }else{
                props.setError(err.message);
            }
        }
    };

  return (
    <>
        <div className="card-body cardLogin px-4 animate__animated animate__backInLeft animate__fast">
            <Form onSubmit={getOtp} className="" style={{ display: !flag ? "block" : "none" }}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                <label className="w-100 p-3 pb-0" style={{ backgroundColor: 'white', borderTopLeftRadius: '15px', borderTopRightRadius: '15px', fontSize: '18px', fontWeight: '500' }}>Número telefónico</label>
                <PhoneInput
                    labels={es}
                    defaultCountry="MX"
                    value={props.number}
                    onChange={(e) => props.onChangeNumber(e)}
                    placeholder="000 000 0000"
                    className="p-3 pt-0"
                    style={{ backgroundColor: 'white', borderBottomLeftRadius: '15px', borderBottomRightRadius: '15px' }}
                />
                <div id="recaptcha-container" className={"d-flex justify-content-center"}>
                </div>
                </Form.Group>
                <div className="text-center">
                    <button type="submit" className="btn btn-dark py-2 w-100 mt-2" style={{ borderRadius: '20px', fontSize: '18px', fontWeight: '500' }}>Enviar Código</button>
                </div>
            </Form>
            <Form onSubmit={verifyOtp} style={{ display: flag ? "block" : "none" }} className="animate__animated animate__backInLeft">
                <Form.Group className="mb-3" controlId="formBasicOtp">
                <label className="w-100 p-3 pb-0" style={{ backgroundColor: 'white', borderTopLeftRadius: '15px', borderTopRightRadius: '15px', fontSize: '18px', fontWeight: '500' }}>Código de verificación</label>
                <input
                    type="text"
                    placeholder="Enviado a su teléfono"
                    className="p-3 pt-0 w-100 inputOTP"
                    style={{ backgroundColor: 'white', borderBottomLeftRadius: '15px', borderBottomRightRadius: '15px', border: 'none' }}
                    onChange={(e) => setOtp(e.target.value)}
                    autoComplete="one-time-code"
                    inputMode="numeric"
                />
                </Form.Group>
                <div className="row">
                    <div className="col-6">
                        <Link to="/">
                            <button type="button" className="btn btn-info py-2 w-100 text-white" style={{ borderRadius: '20px', fontSize: '18px', fontWeight: '500' }}>Cancelar</button>
                        </Link>
                    </div>
                    <div className="col-6">
                        <button type="submit" className="btn btn-dark py-2 w-100" style={{ borderRadius: '20px', fontSize: '18px', fontWeight: '500' }}>Verificar</button>
                    </div>
                </div>
            </Form>
        </div>
    </>
  );
};

export default Login;