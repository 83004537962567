import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import Cargando from './Cargando';
 
import {useUserAuth} from '../context/UserAuthContext';
 
export const PrivateRoute = ({ component: Component, ...rest}) => {
    const currentUser = useUserAuth().user;

    return (
        <Route
        {...rest}
        render={props=> {
            return (
                (typeof currentUser === 'object' && currentUser !== null) ?
                    Object.keys(currentUser).length === 0 ?
                        <Cargando/>
                    :
                        <Component {...props}/>
                :
                    <Redirect to='/acceso' />
            )
        }}
        >
        </Route>
    )
}