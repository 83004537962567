import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Form, Alert } from "react-bootstrap";
import "react-phone-number-input/style.css";
import PhoneInput, { isPossiblePhoneNumber } from "react-phone-number-input";
import { useUserAuth } from "../context/UserAuthContext";

const Signup = (props) => {
  const [error, setError] = useState("");
  const [nombre, setNombre] = useState('');
  const [mes, setMes] = useState('');
  const [dia, setDia] = useState('');
  const [enviarMensajes, setEnviarMensajes] = useState(false);
  const [flag, setFlag] = useState(false);
  const [otp, setOtp] = useState("");
  const [result, setResult] = useState("");
  const { setUpRecaptha } = useUserAuth();

  const getOtp = async (e) => {
      e.preventDefault();
      await setError("");
      if (props.number === "" || props.number === undefined || !isPossiblePhoneNumber(props.number))
          return setError("Ingrese un número telefónico válido.");
      try{
          const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ operacion: 'selectWhere', telefono: props.number })
          };
          const response = await fetch('https://tarjetalealtad.argeos.net/php/DB/consultas.php', requestOptions);
          const data = await response.json();
          if (data.error === 0) {
            if (data.respuesta) {
              setError('Este número ya se encuentra registrado.');            
            }else{
              const response = await setUpRecaptha(props.number);
              setResult(response);
              setFlag(true);  
            }
          }else{
            setError('Error al buscar coincidencias.')
            console.log(data.mensaje);
          }
      }catch (err) {
          setError(err.message);
          setFlag(false);
      }
  };

  const verifyOtp = async (e) => {
      e.preventDefault();
      setError("");
      if (otp === "" || otp === null) return;
      try{
        const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ operacion: 'insert', telefono: props.number, nombre: nombre, mesCumpleanos: mes, diaCumpleanos: dia, enviarMensajes: enviarMensajes })
        };
        const response = await fetch('https://tarjetalealtad.argeos.net/php/DB/consultas.php', requestOptions);
        const data = await response.json();
        if (data.error === 0) {
          await result.confirm(otp);
        }else{
          setError('Error al guardar usuario.');
        }
      }catch (err) {
          const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ operacion: 'delete', telefono: props.number })
          };
          await fetch('https://tarjetalealtad.argeos.net/php/DB/consultas.php', requestOptions);
          if (err.code === 'auth/invalid-verification-code') {
            setError('Código incorrecto.');
          }else{
              setError(err.message);
          }
      }
  };

  const getNoDias = () => {
    let dias = 0;
    if (mes === '01' || mes === '03' || mes === '05' || mes === '07' || mes === '08' || mes === '10' || mes === '12') {
      dias = 31;
    }
    if (mes === '04' || mes === '06' || mes === '09' || mes === '11') {
      dias = 30;
    }
    if (mes === '02'){
      dias = 29;
    }
    return dias;
  }

  const getDias = () => {
      let options = [];
      if (getNoDias() !== 0) {        
        for (let index = 1; index <= getNoDias(); index++) {
          options.push(<option key={index.toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false })} value={index.toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false })}>{index}</option>);                                                         
        }
      }
      return options
  }

  

  return (
    <>
        <div className="card-body cardRegistro px-4">
          {error && <Alert variant="danger" className="animate__animated animate__headShake">{error}</Alert>}
          <Form onSubmit={getOtp}>            
              <Form.Group className="mb-3" controlId="formBasicEmail">
                  <label className="w-100 p-3 pb-0" style={{ backgroundColor: 'white', borderTopLeftRadius: '15px', borderTopRightRadius: '15px', fontSize: '18px', fontWeight: '500' }}>Número telefónico</label>
                  <PhoneInput
                      defaultCountry="MX"
                      value={props.number}
                      onChange={(e) => props.onChangeNumber(e)}
                      placeholder="000 000 0000"
                      className="p-3 pt-0"
                      style={{ backgroundColor: 'white', borderBottomLeftRadius: '15px', borderBottomRightRadius: '15px' }}
                  />
              </Form.Group>
              <label htmlFor="nombre" className="w-100 p-3 pb-0 animate__animated animate__fadeIn animate__fast" style={{ backgroundColor: 'white', borderTopLeftRadius: '15px', borderTopRightRadius: '15px', fontSize: '18px', fontWeight: '500' }}>Nombre</label>
                  <input type="text" name="nombre" id="nombre" className="p-3 pt-0 w-100 inputOTP animate__animated animate__fadeIn animate__fast" style={{ backgroundColor: 'white', borderBottomLeftRadius: '15px', borderBottomRightRadius: '15px', border: 'none' }} required value={nombre} onChange={(e) => setNombre(e.target.value)}/>
              <label htmlFor="mes" className="w-100 p-3 pb-0 mt-3 animate__animated animate__fadeIn animate__fast" style={{ backgroundColor: 'white', borderTopLeftRadius: '15px', borderTopRightRadius: '15px', fontSize: '18px', fontWeight: '500' }}>Cumpleaños <label style={{ fontSize: '16px', color: 'rgba(0,0,0,0.2)' }}> (Opcional)</label></label>
                  <div className="row m-0 animate__animated animate__fadeIn animate__fast">
                      <div className="col-6 p-0" style={{ backgroundColor: 'white', borderBottomLeftRadius: '15px' }}>
                          <select name="mes" id="mes" className="p-3 pt-0 w-100 h-100 inputOTP" style={{ backgroundColor: 'white', borderBottomLeftRadius: '15px', border: 'none' }} value={mes} onChange={(e) => setMes(e.target.value)}>
                            <option value=''>Mes</option>
                            <option value="01">Enero</option>
                            <option value="02">Febrero</option>
                            <option value="03">Marzo</option>
                            <option value="04">Abril</option>
                            <option value="05">Mayo</option>
                            <option value="06">Junio</option>
                            <option value="07">Julio</option>
                            <option value="08">Agosto</option>
                            <option value="09">Septiembre</option>
                            <option value="10">Octubre</option>
                            <option value="11">Noviembre</option>
                            <option value="12">Diciembre</option>
                          </select>
                      </div>
                      <div className="col-6 p-0" style={{ backgroundColor: 'white', borderBottomRightRadius: '15px' }}>
                          <select name="dia" id="dia" className="p-3 pt-0 w-100 h-100 inputOTP" style={{ backgroundColor: 'white', borderBottomRightRadius: '15px', border: 'none' }} value={dia} onChange={(e) => setDia(e.target.value)}>
                            <option value=''>Dia</option>
                            {getDias()}
                          </select>
                      </div>
                  </div>
              <div className="form-check mt-3 animate__animated animate__fadeIn animate__fast">
                <input className="form-check-input" type="checkbox" checked={enviarMensajes} id="flexCheckDefault" onChange={(e) => setEnviarMensajes(e.target.checked)}/>
                <label className="form-check-label" htmlFor="flexCheckDefault" style={{ fontSize: '13px', fontWeight: '500' }}>
                  Recibir ofertas y promociones personalizadas. 
                </label>
              </div>
              <Form.Group className=" animate__animated animate__fadeIn animate__fast">
                <div id="recaptcha-container" className={"justify-content-center mb-1"} style={{ display: !flag ? "flex" : "none" }}></div>
              </Form.Group>
              <div className="text-center" style={{ display: !flag ? "block" : "none" }}>
                <button type="submit" className="btn btn-dark py-2 w-100 mt-3" style={{ borderRadius: '20px', fontSize: '18px', fontWeight: '500' }}>Continuar</button>
              </div>
          </Form>
          <Form onSubmit={verifyOtp} style={{ display: flag ? "block" : "none" }}>
              <Form.Group className="mb-3 mt-3" controlId="formBasicOtp">
              <label className="w-100 p-3 pb-0" style={{ backgroundColor: 'white', borderTopLeftRadius: '15px', borderTopRightRadius: '15px', fontSize: '18px', fontWeight: '500' }}>Código de verificación</label>
              <input
                    type="text"
                    placeholder="Enviado a su teléfono"
                    className="p-3 pt-0 w-100 inputOTP"
                    style={{ backgroundColor: 'white', borderBottomLeftRadius: '15px', borderBottomRightRadius: '15px', border: 'none' }}
                    onChange={(e) => setOtp(e.target.value)}
                    autoComplete="one-time-code"
                    inputMode="numeric"
              />
              </Form.Group>
              <div className="row">
                  <div className="col-6">
                      <Link to="/">
                          <button type="button" className="btn btn-info py-2 w-100 text-white" style={{ borderRadius: '20px', fontSize: '18px', fontWeight: '500' }}>Cancelar</button>
                      </Link>
                  </div>
                  <div className="col-6">
                      <button type="submit" className="btn btn-dark py-2 w-100" style={{ borderRadius: '20px', fontSize: '18px', fontWeight: '500' }}>Verificar</button>
                  </div>
              </div>
          </Form>
        </div>
    </>
  );
};

export default Signup;