import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';

class ContadorVisitas extends Component {
    constructor(props) {
        super(props);
        this.state = {
            recompensas: [],
            seeModalRecompensa: false,
            recompensa: []
        }
    }

    abrirModalRecompensa = (index) => {
        let recompensa = this.props.recompensas.find(e => Number(e.noVisitas) === Number(index));
        this.setState({
            seeModalRecompensa: true,
            recompensa: recompensa
        })
    }

    cerrarModalRecompensa = () => {
        this.setState({
            seeModalRecompensa: false,
            recompensa: []
        })
    }

    getRecompensas = () => {
        let visitasMax = this.props.recompensas[this.props.recompensas.length - 1].noVisitas;
        let recompensas = [];
        for (let index = 1; index <= visitasMax; index++) {
          recompensas.push(
            <div key={index} className='col-4 text-center'>
              {this.getRecompensa(index) ?
                <div>
                  <i className="bi bi-gift" onClick={() => this.abrirModalRecompensa(index)} style={{ fontSize: '40px', color: this.getMarcarVisita(index) ? '#5FE9AA' : '#F0F9F7' }}></i>
                </div>
              :  
                <div>
                <i className="bi bi-handbag-fill" style={{ fontSize: '40px', color: this.getMarcarVisita(index) ? '#5FE9AA' : '#F0F9F7' }}></i>
                </div>
              }
            </div>
          );                                                         
        }
        return recompensas
    }

    getMarcarVisita = (index) => {
        let marcarVisita = false;
        if (this.props.visitas === 0 && this.props.visitasTotales > 0) {
          marcarVisita = true;
        }else{      
          marcarVisita = index <= Number(this.props.visitas);
        }
        return marcarVisita
      }
    
    getRecompensa = (noVisitas) => {
        let recompensas = this.props.recompensas;
        let recompensa = recompensas.find(e => Number(e.noVisitas) === Number(noVisitas));
        return recompensa
    }

    render() { 
        return ( 
            <>
                {
                    this.props.recompensas.length > 0 ?
                        <div className='tarjetaRecompensas mt-4 mx-auto' style={{ maxWidth: '600px' }}>
                            <div className="row">
                              {this.getRecompensas()}
                            </div>
                        </div>
                    :
                        <h1>No se encontraron recompensas</h1>
                }
                <Modal show={this.state.seeModalRecompensa} onHide={this.cerrarModalRecompensa} centered className='modalRecompensa'>
                    <Modal.Header className='d-flex justify-content-center align-items-center' style={{ border: 'none' }}>
                        <h5 className='m-0'>Recompensa {this.state.recompensa.noVisitas} visitas</h5>
                    </Modal.Header>
                    <Modal.Body>
                        <p className='m-0'>{this.state.recompensa.descripcion}</p>
                    </Modal.Body>
                    <Modal.Footer className='d-flex justify-content-start'>
                        <p className='m-0' style={{ fontSize: '12px', color: 'gray', fontWeight: '600' }}>{this.state.recompensa.terminos}</p>
                    </Modal.Footer>
                </Modal>
            </>
         );
    }
}
 
export default ContadorVisitas;