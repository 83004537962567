import React, { Component } from 'react';
import '../css/cargando.css';

class Cargando extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }
    render() { 
        return (  
            <div className="wrapper">
                <div className="circle"></div>
                <div className="circle"></div>
                <div className="circle"></div>
                <div className="shadow"></div>
                <div className="shadow"></div>
                <div className="shadow"></div>
            </div>
        );
    }
}
 
export default Cargando;